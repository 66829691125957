import { Avatar, Button, Dropdown, Menu } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { getNavItem } from '~/components/Layout/AppLayout/AppLayout';
import { LogoutOutlined } from '@ant-design/icons';
import { TOKEN_KEY } from '~/app-configs';
import { useSelector } from 'react-redux';
import logo from '~/assets/images/header/logo_b4e.png';
import AppButton from '~/components/atomic/AppButton/AppButton';
const userDropdownItems = [getNavItem('Đăng xuất', '/auth/logout', <LogoutOutlined />, null)];
import './Header.sass';
import btnNav from '~/assets/images/icon/navBarIcon.svg';
import btnClose from '~/assets/images/icon/Xicon.svg';
const onClickUserAvatar = (item) => {
    if (item?.key == '/auth/logout') {
        handleLogout();
    }
};

function handleLogout() {
    localStorage.removeItem(TOKEN_KEY);
    window.location.reload(false);
}

export default function (props) {
    const userDetail = useSelector((state) => state?.user?.profile);
    const [headerBgColor, setHeaderBgColor] = useState('#D7E4FF');
    const [blockNav, setBlockNav] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', (event) => {
            if (window.pageYOffset >= 900) setHeaderBgColor('#fff');
            if (window.pageYOffset < 900) setHeaderBgColor('#D7E4FF');
        });
        return () => window.removeEventListener('scroll');
    }, []);

    function handleNavMobile() {
        setBlockNav(!blockNav);
        console.log(blockNav);
    }
    return (
        <div
            style={{
                backgroundColor: headerBgColor,
            }}
            className="fixed_header"
        >
            <div
                className="container"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100vw',
                    height: '70px',
                }}
            >
                <div className="flex-center">
                    <a href="/">
                        <img src={logo} style={{ height: '30px' }}></img>
                    </a>
                </div>

                <div className="list_item_nav">
                    <a className="item_nav_text" href="#introduce">
                        Giới thiệu
                    </a>
                    <a className="item_nav_text" href="#solution">
                        Giải pháp
                    </a>
                    <a className="item_nav_text" href="#about_us">
                        Về chúng tôi
                    </a>
                </div>
                <div className="nav_bars-btn" onClick={handleNavMobile}>
                    <img src={btnNav}></img>
                </div>
                <input
                    type="checkbox"
                    id="nav-mobile-input"
                    className="nav__input"
                    hidden
                    checked={blockNav === true ? 'checked' : ''}
                ></input>
                <div onClick={handleNavMobile} className="page_overLayout"></div>
                <div className="nav_mobile">
                    <img src={logo} className="logo_nav"></img>
                    <div onClick={handleNavMobile} className="nav_mobile-close">
                        <img src={btnClose}></img>
                    </div>
                    <div className="nav_mobile-list">
                        <div>
                            <a className="nav_mobile-link" href="#introduce" onClick={handleNavMobile}>
                                Giới thiệu
                            </a>
                        </div>
                        <div>
                            <a className="nav_mobile-link" href="#solution" onClick={handleNavMobile}>
                                Giải pháp
                            </a>
                        </div>
                        <div>
                            <a className="nav_mobile-link" href="#about_us" onClick={handleNavMobile}>
                                Về chúng tôi
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
