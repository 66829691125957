import React from 'react';
import FourdotLight from '../FourdotLight/FourdotLight';
import FourdotDark from '../FourdotDark/FourdotDark';
import classNames from 'classnames/bind';
import styles from './AppButton.module.sass';

let cx = classNames.bind(styles);

function AppButton({
    children,
    isWhite = false,
    loading = false,
    disabled = false,
    loadingIcon = <FourdotLight />,
    ...rest
}) {
    return (
        <button
            disabled={disabled}
            className={cx('app-button', 'flex-center', isWhite ? 'app-button-white' : '')}
            {...rest}
        >
            {loading ? isWhite ? <FourdotDark /> : <FourdotLight /> : children}
        </button>
    );
}

export default AppButton;
