import { AppstoreOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Image, Layout, Menu } from 'antd';
import 'antd/dist/antd.css';
import hustLogo from '~/assets/images/header/hust-logo.jpeg';
import hustLogoNgang from '~/assets/images/header/logo-hust-ngang.jpg';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SIDER_COLLAPSE } from '~/app-configs';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppHeader from '~/components/Layout/components/Header';
import './AppLayout.sass';
const { Sider, Content } = Layout;

export function getNavItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}
const sliderItems = [
    getNavItem('Tài khoản', 'account', <UserOutlined />, [
        getNavItem('Danh sách tài khoản', '/accounts/list'),
        getNavItem('Thêm tài khoản', '/accounts/add'),
    ]),
    getNavItem('Cấu hình chứng chỉ', '/config/select-ceft', <AppstoreOutlined />, null),
    getNavItem('Cấu hình hợp đồng', '/config/blockchain', <SettingOutlined />, null),
];

function AppLayout({ children, match }) {
    const [collapsed, setCollapsed] = useState(localStorage.getItem(SIDER_COLLAPSE) ?? false);
    const history = useHistory();
    const currentRouter = useSelector((state) => state.router.location);
    const [selectedSider, setSelectedSider] = useState(getSelectedNav());

    function toggleSider() {
        setCollapsed(!collapsed);
        localStorage.setItem(SIDER_COLLAPSE, !collapsed);
    }

    const onClickSliderMenu = (item) => {
        history.push(item.key);
    };

    function getSelectedNav() {
        if (currentRouter?.pathname.includes('/config/sign-ceft/')) {
            return '/config/select-ceft';
        }
        return currentRouter?.pathname;
    }

    useEffect(() => {
        console.log('selectedSider: ', selectedSider);
    }, [selectedSider]);

    return (
        <Layout style={{ backgroundColor: '#fff' }}>
            <Layout className="app-layout " style={{ backgroundColor: '#fff' }}>
                <AppHeader />
                <Content
                    style={{
                        minHeight: 280,
                        position: 'relative',
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}

export default AppLayout;
